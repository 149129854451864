.outer-container {
  background-color: #e6ead8;
  height: 100vh; /* Adjust the height as needed */
}

.main-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  background-color: white;
  padding: 5rem 1.5rem 2rem;
  max-height: 400px; /* Set an initial max height */
  height: 400px; /* Set an initial height */
  transition: max-height 0.3s ease, height 0.3s ease; /* Add transition with "ease" timing function */
}

.main-container.expanded {
  max-height: 530px; /* Set an expanded max height */
  height: 530px; /* Set an expanded height */
}

.image-container {
  position: absolute;
  top: 15%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.auth-container {
  margin-right: 5.3rem;
  margin-top: 3rem;
}

.form-container input[type="text"],
.form-container input[type="password"] {
  width: calc(147% - 2rem);
  padding: 8px;
  margin: 13px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-container label {
  font-weight: bold;
}

.input-group {
  margin-bottom: 10px;
}

.button-container {
  margin-top: 1rem;
}

.button {
  background-color: #b9be10;
  border: none;
  padding: 0.5rem;
  border-radius: 0.4rem;
  width: calc(147% - 2rem); /* Adjust the margin value as needed */
  color: white;
  font-size: medium;
}

.p_image {
  width: 14rem;
  padding-top: 20px;
}

.p_logo {
  position: relative;
  margin-left: 5rem;
  margin-top: 4rem;
  width: 4rem;
}

.p_logo::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-bottom: 1rem solid black; /* Adjust color and thickness as needed */
  transform: translateY(-50%);
}

.rememberMebox {
  width: 20px;
}

.rememberMe {
  margin-left: 4px;
  font-size: 13px;
}
.help {
  margin-top: 1rem;
  font-size: 13px !important;
}

.help:hover {
  cursor: pointer;
  color: #b9be10;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-bottom: 0 !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: none !important;
}

.subtext {
  font-size: 13px !important;
}
