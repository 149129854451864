.previewIcon{
  cursor: pointer;
  color: #B9BE10;
  width: 40px;
}
.previewIcon:hover{
  color: #fff;
}
.saveBtn{
  background-color: #B9BE10;
  padding: 5px 20px;
  border-radius: 8px;
}