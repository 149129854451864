.addbtn{
    margin-bottom: 1rem;
    float: right;

}

symbol{
    font-size: 19px;
    margin-top: -3px;
    padding-right: 10px;
}
.t_bold{
    font-weight: bold !important;
}