.openModel {
    background-color: #004237 !important;
    color: white !important;
    border: none !important;
    border-radius: 4px !important;
    padding: 10px 20px !important;
    text-align: center !important;
    font-size: 18px !important;
    font-weight: bold !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
    margin-bottom: 1rem !important;
  
    &:hover {
      background-color: #c1cc24 !important;
    }
  
    &:focus {
      outline: none !important;
      box-shadow: 0 0 0 2px rgba(154, 255, 38, 0.5) !important;
    }
  }
  .modelParent {
    display: flex !important;
    justify-content: space-between !important;
  }
  .pageTitle {
    font-size: 28px !important;
    font-weight: bold !important;
  }
  
  .modelTitle {
    font-size: 20px !important;
    padding-left: 30px !important;
  }
  .cancelDelete {
    background-color: #004237 !important;
    color: white !important;
    border: none !important;
    border-radius: 4px !important;
    padding: 8px 16px !important;
    text-align: center !important;
    font-size: 14px !important;
    font-weight: bold !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
    margin-bottom: 1rem !important;
  
    &:hover {
      background-color: #c1cc24 !important;
    }
  
    &:focus {
      outline: none !important;
      box-shadow: 0 0 0 2px rgba(154, 255, 38, 0.5) !important;
    }
  }
  .modelButton {
    background-color: red !important;
    color: white !important;
    border: none !important;
    border-radius: 4px !important;
    padding: 8px 16px !important;
    text-align: center !important;
    font-size: 14px !important;
    font-weight: bold !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
    margin-bottom: 1rem !important;
  
    &:hover {
      background-color: #c1cc24 !important;
    }
  
    &:focus {
      outline: none !important;
      box-shadow: 0 0 0 2px rgba(154, 255, 38, 0.5) !important;
    }
  }
  