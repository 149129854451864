
/* 
  .mainBody {
    display: flex;
    justify-content: center;
    margin: 20px 10px;
  }
  .mainBodyChild{
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 1200px;
    padding: 20px;
  } */
  .CADFiles,
  .overview{
    padding: 10px 0;
    border-bottom: 1px solid #e1e1e1;
  }
  .CADFilesChild,
  .overviewChild {
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  @media screen and (max-width: 1035px) {
    .CADFilesChild,
    .overviewChild {
      grid-template-columns: repeat(2, 1fr); /* Display two cards per row below 1035px */
    }
    .CADFiles,
  .overview{
  display: flex;
  justify-content: center;
  flex-direction: column;
  }
  }
  .overview h3,
  .CADFiles h3{
    font-size: 24px;
  }
  .CADFilesCard,
  .overviewCard {
    padding: 10px 0;
    width: 300px;
    box-shadow: 1px 5px 10px #d2d893;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .CADFilesCard:hover,
  .overviewCard:hover {
    background-color: #d2d893;
    box-shadow: 1px 10px 10px #d2d893;
  }
  .CADFilesCard img,
  .overviewCard img {
    margin: 10px 0;
    width: 50px;
  }
  .overviewTitle {
    font-size: 18px;
    text-align: center;
    /* padding: 20px 0; */
  }
  .CADFiles {
    margin: 30px 0;
  }
  .CADFiles h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }