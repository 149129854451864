
// .container{
//   overflow-x: hidden;
// }
.btn {
    display: flex;
    justify-content: flex-end;
  }
  
  .show-btn {
    border: none;
    color: var(--primary);
    background-color: white;
    margin-right: 1rem;
    margin-top: -7rem;
  }
  
  .modalbtn-conatiner{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    
  }
  
  .btn-prev{
    border: 1px solid black;
    background-color: white;
    font-size: 2rem;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  .btn-next{
    border: 1px solid black;
    background-color: white;
    font-size: 2rem;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin: 0px 10px;
  }
  
  .pageno{
    font-size: .9rem;
  text-align: center;}
  
  .picture{
    cursor: pointer;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
  .pdf-div{
    border: 1px solid #a9a4a4;
  }
  
  @media only screen and (max-width: 414px) {
    .picture{
      cursor: pointer;
      margin-left: 2rem;
    }
  }
  
  @media only screen and (max-width: 375px) {
    .picture{
      margin-left: 1rem;
    }
    
  }
  
  
  