.addpar{
    margin-bottom: 1rem;
    /* display: flex; */
    /* justify-content: flex-end;  */
    float: right;
}

.symbol{
    font-size: 19px;
    margin-top: -3px;
    padding-right: 10px;
}


.css-1ygcj2i-MuiTableCell-root{
font-weight: bold !important;
}
.t_bold{
    font-weight: bold !important;
}