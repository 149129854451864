.previewMainSection{
  display: flex;
  justify-content: center;
}
.previewBody{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
  width: 1000px;
}

