.navBar{
  position: sticky;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #37343523;
  z-index: 1;
}
.portalOverview{
  cursor: pointer;
}
.navBarChild{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #fff;
  color: #000;
}
.packsizeLogo{
  width: 70%;
}
.navLink{
  display: flex;
  align-items: center;
  gap: 3rem;
  font-size: 18px;

}
.partnetLogin{
  background-color: #b9be10;
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.user_img{
  background-color: #b9be10;
  border-radius: 6rem;
  width: 40px;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}
/* Define styles for the dropdown items */
.userDropdownitem {
  position: absolute;
  top: 100%;
  right: 0;
  width: 150px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9;
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Style each dropdown item */
.userDropdownitem li {
  padding: 10px 20px; /* Adjust padding as needed */
  cursor: pointer;
  transition: background-color 0.2s; /* Add a smooth transition effect */
}

.dropdown_content li:hover {
  background-color: #f1f1f1;
  color: black;
}
// .hamburgerBars {
//   display: none;
//   cursor: pointer;
// }

@media screen and (max-width: 1100px) {
  .hamburgerParent{
    position: fixed;
    top: 15px;
    right: 20px;
  }
  .navBarChild{
    padding: 20px 30px;
  }
  .navLink {
    display: none; /* Hide the navigation links when screen size is less than 1100px */
  }

  .hamburgerBars {
    display: flex;
    gap: 4px;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #CFCECD;
    border-radius: 8px;
  }

  .hamburgerBars:hover{
    border: 1px solid #353430;
  }
//   .userDropdownitem {
//     display: block; /* Display the user dropdown when screen size is less than 1100px */
//   }
.hamburgerBar{
  border: 1px solid #b9be10;
  width: 15px;
  transition: 0.4s;
}
.hamburgerNavLink{
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 10px;

}
  .hamburgerNavLinkParent{
    position: fixed;
    top: 50px;
    right: 10px;
    background-color: #fff;
    width: 100px;
  }
}