.tableHeader{
    background-color: #c1cc24 !important;
    color: white !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}
.tableBody{
    font-size: 18px !important;
    font-weight: 500 !important;
}
.modelButton {
    background-color: #004237 !important; 
    color: white !important;
    border: none !important;
    border-radius: 4px !important; 
    padding: 8px 16px !important;
    text-align: center !important;
    font-size: 14px !important;
    font-weight: bold !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
    margin-bottom: 1rem !important;
  
  
    &:hover {
      background-color: #c1cc24 !important;
    }
  
    &:focus {
      outline: none !important;
      box-shadow: 0 0 0 2px rgba(154, 255, 38, 0.5) !important;
    }
  }