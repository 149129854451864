.popper-div {
  padding: 5px;
}
.popper-title{
  padding: 7px 1rem;
}
.popper-parent{
  display:flex;
  align-items: center;
  padding: 5px 4rem 5px 1rem;
}
.popper-child {
  cursor: pointer;
  color:#000000;
}
.popper-child-action{
  padding-left:3px;
  padding-top: 5px;
  border-radius: 5px;
  margin:5px;
  cursor: pointer;
}
.popper-child-action:hover{
  background-color: #eff2f5;
}
.popper-child :hover{
  background-color: #eff2f5;
  border-radius: 5px;
}
.popper-text{
  padding-bottom: 5px;
}
.popper-button{
  color:black;
}
.popper-div-action{
  border-radius: 5px;
  padding: 5px;
  /* margin: 5px; */
  /* border:1px solid #9fa2a5; */
  /* box-shadow: 0 0 10px rgba(170, 168, 168, 0.2);  */
  
}
.action-div{
  border-radius: 5px;
  padding: 5px;
  /* margin: 5px; */
  /* border: 1px solid #9fa2a5; */
  /* box-shadow: 0 0 10px rgba(170, 168, 168, 0.2);  */
}
.btn-section {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.form-btn {
  display: flex;
  padding: 8px 5px;
  border-radius: 5px;
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.form-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
.description-info{
  margin:14px 0;
}
.block-wrapper {
  position: relative;
}
.block-wrapper:hover {
  .block-actions {
    display: block;
  }
}
.save-status{
  padding-top: 5px;
}
.block-actions {
  /* display: none; */
  position: absolute;
  top: 5px;
  right: 0rem;
  cursor: pointer;
}

.pdfBox {
  display: flex;

  justify-content: space-between;

  align-items: center;


  border: 1px solid #eff0f1;

  padding: 10px 25px;

  border-radius: 5px;

  margin: 20px auto;

  background-color: #eff2f5;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  text-align: left;
}

.pdfHeader {
  font-size: 18px;

  color: #707684;
}

.pdfContent {
  font-size: 16px;

  margin-top: 5px;

  color: #707684;
}

.viewPdf {
  background-color: #919ba1;

  color: #fff;

  border: none;

  border-radius: 5px;

  padding: 5px 10px;

  cursor: pointer;
}

.viewPdf:hover {
  background-color: #3d4f5a;
}

.text-type-div{
  /* border: 1px solid black; */
  margin-bottom: 1rem;
  border: 1px solid gray;
  padding: 1rem 1rem;
  background-color: rgb(206, 197, 197);
}